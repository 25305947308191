import type Sentry from "@sentry/sveltekit";

const services = {
	root: { dsn: "https://03da2daf4345454e9a8f3c04b54f3237@o4504587129913344.ingest.sentry.io/4504986309296128" },
	frontend: { dsn: "https://e0ef9222a7684b9a9a9565a80742064a@o4504587129913344.ingest.sentry.io/4505312390610944" },
	backend: { dsn: "https://2a0f8ba49baa4a5686c7ba33b2cd989f@o4504587129913344.ingest.sentry.io/4505312392904704" },
	internal: { dsn: "https://d79d3d3e061e4e3087466d3412cc65db@o4504587129913344.ingest.sentry.io/4505312365838336" },
	registration: { dsn: "https://7c16a6f07bec412fa010af8df1713321@o4504587129913344.ingest.sentry.io/4505312395395072" },
	about: { dsn: "https://204e303e01734531b2ef570e22fa9af2@o4504587129913344.ingest.sentry.io/4505312394215424" },
	"cs-festwoche-exhibitorportal": { dsn: "https://688ad91a52b6a51eacc6c05d100ce8ed@o4504587129913344.ingest.us.sentry.io/4507483122434048" },
} satisfies Record<string, Sentry.NodeOptions>;

const defaultSentryOptions = {
	tracesSampleRate: 0.1,
} satisfies Sentry.NodeOptions;

export function getSentryConfig(service: keyof typeof services) {
	return {
		...defaultSentryOptions,
		...services[service],
	} as const;
}
