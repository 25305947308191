import { dev } from "$app/environment";
import * as Sentry from "@sentry/sveltekit";
import { getSentryConfig } from "$myLib/config/sentry";

if (!dev) {
	Sentry.init(getSentryConfig("frontend"));
}

export const handle = Sentry.sentryHandle;

export const handleError = Sentry.handleErrorWithSentry();
